import React from 'react';
import 'babel-polyfill';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import MainLayout from './layouts/MainLayout';
import routes from './routes';

import Error404 from './pages/Errors/Error404';

import './App.scss';

function App() {
  return (
    <Router>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route path={route.path} exact={route.exact} key={index}>
              <MainLayout>{route.component}</MainLayout>
            </Route>
          );
        })}

        <Route>
          <MainLayout>
            <Error404 />
          </MainLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
