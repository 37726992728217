import React from 'react';
import { Container, Carousel, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import styles from './Start.module.scss';

import carousel1 from './images/1.jpg';
import carousel2 from './images/2.jpg';
import carousel3 from './images/3.jpg';
import carousel4 from './images/4.jpg';
import carousel5 from './images/5.jpg';

import labelFibreCement from './images/label_fibre_cement.jpg';
import labelRoofs from './images/label_roofs.jpg';
import labelFacades from './images/label_facades.jpg';
import labelFurniture from './images/label_furniture.png';

function Start() {
  const images = [carousel1, carousel2, carousel3, carousel4, carousel5];

  return (
    <Container className="mt-5 mb-5">
      <Carousel>
        {images.map((image, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={image}
                alt={`Obraz ${index}`}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>

      <Row className="mt-4">
        <Col xs={12} sm={3}>
          <div className={styles.container}>
            <NavLink to="/produkty">
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${labelFibreCement})` }}
              ></div>
              <div className={styles.label}>Włókno cement</div>
            </NavLink>
          </div>
        </Col>

        <Col xs={12} sm={3}>
          <div className={styles.container}>
            <NavLink to="/produkty/fasady">
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${labelFacades})` }}
              ></div>
              <div className={styles.label}>Fasady</div>
            </NavLink>
          </div>
        </Col>

        <Col xs={12} sm={3}>
          <div className={styles.container}>
            <NavLink to="/produkty/dachy/plytki_dachowe">
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${labelRoofs})` }}
              ></div>
              <div className={styles.label}>Dachy</div>
            </NavLink>
          </div>
        </Col>

        <Col xs={12} sm={3}>
          <div className={styles.container}>
            <NavLink to="/produkty/meble-wlokno-cementowe/furniture_and_accessories">
              <div
                className={styles.image}
                style={{ backgroundImage: `url(${labelFurniture})` }}
              ></div>
              <div className={styles.label}>Meble włókno cementowe</div>
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Start;
