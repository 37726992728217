import React from 'react';

export default [
  {
    name: 'Birdy Nistbox',
    description: <p></p>,
    images: [
      require('./images/birdy_nistbox_1.jpg'),
      require('./images/birdy_nistbox_2.jpg'),
      require('./images/birdy_nistbox_3.jpg'),
      require('./images/birdy_nistbox_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>21 cm x 16 cm x 22 cm (1,3 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Cheers wine rack',
    description: <p></p>,
    images: [
      require('./images/cheers_wine_rack_1.jpg'),
      require('./images/cheers_wine_rack_2.jpg'),
      require('./images/cheers_wine_rack_3.jpg'),
      require('./images/cheers_wine_rack_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>38 cm x 25 cm x 31 cm (4,6 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Dune Lounge',
    description: <p></p>,
    images: [
      require('./images/dune_lounge_1.jpg'),
      require('./images/dune_lounge_2.jpg'),
      require('./images/dune_lounge_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>96 cm x 95 cm x 60 cm (48 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Dune Table',
    description: <p></p>,
    images: [
      require('./images/dune_table_1.jpg'),
      require('./images/dune_table_2.jpg'),
      require('./images/dune_table_3.jpg'),
      require('./images/dune_table_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>96 cm x 89 cm x 36 cm (44 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Ecal Chair',
    description: <p></p>,
    images: [
      require('./images/ecal_chair_1.jpg'),
      require('./images/ecal_chair_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>42 cm x 32 cm x 42 cm (8 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Ecal Desk',
    description: <p></p>,
    images: [
      require('./images/ecal_desk_1.jpg'),
      require('./images/ecal_desk_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>70 cm x 49 cm x 27 cm (12 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Ella',
    description: <p></p>,
    images: [
      require('./images/ella_1.jpg'),
      require('./images/ella_2.jpg'),
      require('./images/ella_3.jpg'),
      require('./images/ella_4.jpg'),
      require('./images/ella_5.jpg'),
      require('./images/ella_6.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>34 cm x 27 cm x 57.5 cm (6 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Guhl Chair',
    description: <p></p>,
    images: [
      require('./images/guhl_chair_1.jpg'),
      require('./images/guhl_chair_2.jpg'),
      require('./images/guhl_chair_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>79 cm x 54 cm x 61 cm (23 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Guhl Desk',
    description: <p></p>,
    images: [
      require('./images/guhl_desk_1.jpg'),
      require('./images/guhl_desk_2.jpg'),
      require('./images/guhl_desk_3.jpg'),
      require('./images/guhl_desk_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>72 cm x 40 cm x 22 cm (12 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Hokuspokus Desk',
    description: <p></p>,
    images: [
      require('./images/hokuspokus_desk_1.jpg'),
      require('./images/hokuspokus_desk_2.jpg'),
      require('./images/hokuspokus_desk_3.jpg'),
      require('./images/hokuspokus_desk_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>45 cm x 43 cm x (10 kg)</div>
            <div>58 cm x 36 cm x (10 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Mold Lamp',
    description: <p></p>,
    images: [
      require('./images/mold_lamp_1.jpg'),
      require('./images/mold_lamp_2.jpg'),
      require('./images/mold_lamp_3.jpg'),
      require('./images/mold_lamp_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>27 cm x 32 cm x (2,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Sponeck Chair',
    description: <p></p>,
    images: [
      require('./images/sponeck_chair_1.jpg'),
      require('./images/sponeck_chair_2.jpg'),
      require('./images/sponeck_chair_3.jpg'),
      require('./images/sponeck_chair_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>775 cm x 50 cm x 60 cm (28 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Sponeck Desk',
    description: <p></p>,
    images: [
      require('./images/sponeck_desk_1.jpg'),
      require('./images/sponeck_desk_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>50 cm x 25 cm x (21 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Tetris Shelf',
    description: <p></p>,
    images: [
      require('./images/tetris_shelf_1.jpg'),
      require('./images/tetris_shelf_2.jpg'),
      require('./images/tetris_shelf_3.jpg'),
      require('./images/tetris_shelf_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>81 cm x 35 cm x 54 cm (9 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'The Bench',
    description: <p></p>,
    images: [
      require('./images/the_bench_1.jpg'),
      require('./images/the_bench_2.jpg'),
      require('./images/the_bench_3.jpg'),
      require('./images/the_bench_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>160 cm x 45 cm x 46 cm (43 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Trash Cube Stool',
    description: <p></p>,
    images: [
      require('./images/trash_cube_stool_1.jpg'),
      require('./images/trash_cube_stool_2.jpg'),
      require('./images/trash_cube_stool_3.jpg'),
      require('./images/trash_cube_stool_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>31 cm x 31 cm x 36 cm (48 kg)</div>
          </>
        ),
      },
    ],
  },
];
