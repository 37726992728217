import React, { useState } from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";

import styles from "./CookiesBanner.module.scss";

const STORAGE_KEY = "cookie_notice_accepted";

function CookiesBanner({ children }) {
  const [accepted, setAccepted] = useState(
    Boolean(localStorage.getItem(STORAGE_KEY))
  );

  if (accepted) {
    return null;
  }

  const accept = () => {
    localStorage.setItem(STORAGE_KEY, true);
    setAccepted(true);
  };

  return (
    <div className={classNames("p-2", styles.container)}>
      <Container>
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
        poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich
        użycie.{" "}
        <span className={styles.accept} onClick={accept}>
          ZGODA
        </span>
      </Container>
    </div>
  );
}

export default CookiesBanner;
