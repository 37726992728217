import React from 'react';

export default [
  {
    name: 'Quadra 30x60 wzór angielski',
    description: (
      <>
        <p>
          Prostota, a wręcz surowość formy płytek Quadra uwypukla
          charakterystyczną harmonię dachu z architekturą domu i jego
          otoczeniem.
        </p>
      </>
    ),
    images: [
      require('./images/quadra_30x60_ang_1.jpg'),
      require('./images/quadra_30x60_ang_2.jpg'),
    ],
    properties: [
      {
        name: 'Powierzchnia',
        value: 'Gładka / Struktura',
      },
      {
        name: 'Wielkość (mm)',
        value: '300x600',
      },
      {
        name: 'Grubość (mm)',
        value: '4.0',
      },
      {
        name: 'Zużycie (szt./m2)',
        value: (
          <>
            <div>13,8 (gładka)</div>
            <div>14,6 (struktura)</div>
          </>
        ),
      },
      {
        name: 'Kolory',
        value: 2,
      },
    ],
  },
  {
    name: 'Bravan 60x30',
    description: (
      <>
        <p>
          Piękny, naturalny wygląd powierzchni i krawędzi płytek Bravan jest
          porównywalny do łupka naturalnego. Idealnie nadają się do budynków z
          historią, gdzie dach wykonany z tych płytek uwydatni ich charakter
        </p>
      </>
    ),
    images: [
      require('./images/bravan_60x30_1.jpg'),
      require('./images/bravan_60x30_2.jpg'),
    ],
    properties: [
      {
        name: 'Powierzchnia',
        value: 'Struktura',
      },
      {
        name: 'Wielkość (mm)',
        value: '400x400',
      },
      {
        name: 'Grubość (mm)',
        value: '4.0',
      },
      {
        name: 'Zużycie (szt./m2)',
        value: (
          <>
            <div>od 10,1</div>
          </>
        ),
      },
      {
        name: 'Kolory',
        value: 2,
      },
    ],
  },
  {
    name: 'Diamond 40x40',
    description: (
      <>
        <p>
          Płytki Diamond wydobyły na światło dzienne prostą formę, zapomnianą
          elegancję oraz prestiż łupka dachowego. Układane pod kątem, kwadratowe
          płytki tworzą pokrycie z rombów przypominające z wyglądu oszlifowany
          diament.
        </p>
      </>
    ),
    images: [
      require('./images/diamond_40x40_1.jpg'),
      require('./images/diamond_40x40_2.jpg'),
    ],
    properties: [
      {
        name: 'Powierzchnia',
        value: 'Gładka / Struktura',
      },
      {
        name: 'Wielkość (mm)',
        value: '400x400',
      },
      {
        name: 'Grubość (mm)',
        value: '4.0',
      },
      {
        name: 'Zużycie (szt./m2)',
        value: (
          <>
            <div>od 10,1</div>
          </>
        ),
      },
      {
        name: 'Kolory',
        value: 2,
      },
    ],
  },
  {
    name: 'Płytki do obróbek dachowych',
    description: (
      <>
        <p>
          Płytki płaskie Quadra 20x20 i 30x30 służą do wykończenia połaci
          dachowej takich jak lukarny, obicia kominów, deski szczytowe i
          okapowe. Płytki zabezpieczają te elementy dachu przed silnymi porywami
          wiatru, wnikaniem opadów oraz przypadkowym zaprószeniem ognia pod
          połacią.
        </p>
      </>
    ),
    images: [
      require('./images/quadra_20x20_30x30_1.jpg'),
      require('./images/quadra_20x20_30x30_2.jpg'),
    ],
    properties: [
      {
        name: 'Powierzchnia',
        value: 'Struktura',
      },
      {
        name: 'Wielkość (mm)',
        value: '200x200 / 300x300',
      },
      {
        name: 'Grubość (mm)',
        value: '4.0',
      },
      {
        name: 'Zużycie (szt./m2)',
        value: (
          <>
            <div>39 / 25</div>
          </>
        ),
      },
      {
        name: 'Kolory',
        value: 5,
      },
    ],
  },
];
