import React from 'react';

import ProductItem from '../../../shared/ProductItem/ProductItem';

import list from './lista';

function Fasady() {
  return (
    <>
      <h1>Fasady</h1>

      {list.map(product => (
        <ProductItem product={product} key={product.name} />
      ))}
    </>
  );
}

export default Fasady;
