import React from 'react';

export default [
  {
    name: 'Aladin',
    description: <p>equipped with water overflow and water storage</p>,
    images: [
      require('./images/aladin_1.jpg'),
      require('./images/aladin_2.jpg'),
      require('./images/aladin_3.jpg'),
      require('./images/aladin_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>112 cm x 59 cm (73 kg)</div>
            <div>138 cm x 73 cm (122 kg)</div>
            <div>165 cm x 73 cm (145 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Alto',
    description: <p></p>,
    images: [
      require('./images/alto_1.jpg'),
      require('./images/alto_2.jpg'),
      require('./images/alto_3.jpg'),
      require('./images/alto_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>39 cm x 55 cm (9,5 kg)</div>
            <div>42 cm x 65 cm (12,5 kg)</div>
            <div>45 cm x 75 cm (16 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Balconia',
    description: <p></p>,
    images: [
      require('./images/balconia_1.jpg'),
      require('./images/balconia_2.jpg'),
      require('./images/balconia_3.jpg'),
      require('./images/balconia_4.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>40 cm x 17 cm x 17 cm (3,5 kg)</div>
            <div>60 cm x 17 cm x 17 cm (5,5 kg)</div>
            <div>80 cm x 17 cm x 17 cm (6 kg)</div>
            <div>100 cm x 17 cm x 17 cm (9,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Biasca',
    description: <p></p>,
    images: [
      require('./images/biasca_1.jpg'),
      require('./images/biasca_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>93 cm x 93 cm x 32 cm (14,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Bonsai',
    description: <p></p>,
    images: [
      require('./images/bonsai_1.jpg'),
      require('./images/bonsai_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>112 cm x 40 cm (33 kg)</div>
            <div>138 cm x 50 cm (50 kg)</div>
            <div>165 cm x 60 cm (86 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Confetti',
    description: <p></p>,
    images: [require('./images/confetti_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>60 cm x 60 cm x 25 cm (6 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 25',
    description: <p></p>,
    images: [require('./images/delta_25_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>80 cm x 25 cm x 25 cm (13 kg)</div>
            <div>100 cm x 25 cm x 25 cm (15 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 35',
    description: <p></p>,
    images: [require('./images/delta_35_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>80 cm x 30 cm x 35 cm (19 kg)</div>
            <div>100 cm x 30 cm x 35 cm (23,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 45 rectangular',
    description: <p></p>,
    images: [
      require('./images/delta_45_rectangular_1.jpg'),
      require('./images/delta_45_rectangular_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>60 cm x 45 cm x 45 cm (23 kg)</div>
            <div>80 cm x 45 cm x 45 cm (29 kg)</div>
            <div>100 cm x 45 cm x 45 cm (33 kg)</div>
            <div>140 cm x 45 cm x 45 cm (45 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 45 round',
    description: <p></p>,
    images: [
      require('./images/delta_45_round_1.jpg'),
      require('./images/delta_45_round_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>50 cm x 45 cm (16,5 kg)</div>
            <div>75 cm x 45 cm (27,5 kg)</div>
            <div>100 cm x 45 cm (42 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 45 square',
    description: <p></p>,
    images: [
      require('./images/delta_45_square_1.jpg'),
      require('./images/delta_45_square_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>45 cm x 45 cm x 45 cm (18,5 kg)</div>
            <div>60 cm x 60 cm x 45 cm (27 kg)</div>
            <div>80 cm x 80 cm x 45 cm (35 kg)</div>
            <div>100 cm x 100 cm x 45 cm (53 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 60 rectangular',
    description: <p>equipped with water overflow and water storage</p>,
    images: [
      require('./images/delta_60_rectangular_1.jpg'),
      require('./images/delta_60_rectangular_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>80 cm x 55 cm x 60 cm (40 kg)</div>
            <div>100 cm x 55 cm x 60 cm (46 kg)</div>
            <div>120 cm x 55 cm x 60 cm (52,5 kg)</div>
            <div>140 cm x 55 cm x 60 cm (60,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 60 round',
    description: <p>equipped with water overflow and water storage</p>,
    images: [require('./images/delta_60_round_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>75 cm x 60 cm (33 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Delta 60 square',
    description: <p>equipped with water overflow and water storage</p>,
    images: [require('./images/delta_60_square_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>60 cm x 60 cm x 60 cm (35 kg)</div>
            <div>80 cm x 80 cm x 60 cm (46 kg)</div>
            <div>100 cm x 100 cm x 60 cm (65,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Geisha',
    description: <p></p>,
    images: [
      require('./images/geisha_1.jpg'),
      require('./images/geisha_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>44 cm x 65 cm (12 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Kyoto',
    description: <p></p>,
    images: [require('./images/kyoto_1.jpg'), require('./images/kyoto_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>35 cm x 36 cm (6 kg)</div>
            <div>44 cm x 45 cm (8 kg)</div>
            <div>53 cm x 55 cm (13,5 kg)</div>
            <div>70 cm x 54 cm (20 kg)</div>
            <div>90 cm x 70 cm (49 kg)</div>
            <div>120 cm x 90 cm (88 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Kyoto Low',
    description: <p></p>,
    images: [require('./images/kyoto_low_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>44 cm x 36 cm (7 kg)</div>
            <div>53 cm x 40 cm (12,5 kg)</div>
            <div>70 cm x 45 cm (17,5 kg)</div>
            <div>90 cm x 50 cm (46 kg)</div>
            <div>120 cm x 65 cm (80 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Landing',
    description: <p></p>,
    images: [
      require('./images/landing_1.jpg'),
      require('./images/landing_2.jpg'),
      require('./images/landing_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>65 cm x 63,4 cm x 54 cm (17,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Lausanne',
    description: <p></p>,
    images: [
      require('./images/lausanne_1.jpg'),
      require('./images/lausanne_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: <></>,
      },
    ],
  },
  {
    name: 'Maia',
    description: <p></p>,
    images: [
      require('./images/maia_1.jpg'),
      require('./images/maia_2.jpg'),
      require('./images/maia_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>59 cm x 50 cm (15 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Ondagreen',
    description: <p></p>,
    images: [
      require('./images/ondagreen_1.jpg'),
      require('./images/ondagreen_2.jpg'),
      require('./images/ondagreen_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>171 cm x 86 cm x 80 cm</div>
            <div>258 cm x 120 cm x 80 cm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Osaka',
    description: <p></p>,
    images: [require('./images/osaka_1.jpg'), require('./images/osaka_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>35 cm x 35 cm x 36 cm (6 kg)</div>
            <div>44 cm x 44 cm x 45 cm (9,5 kg)</div>
            <div>53 cm x 53 cm x 55 cm (16,75 kg)</div>
            <div>70 cm x 70 cm x 54 cm (26 kg)</div>
            <div>90 cm x 90 cm x 70 cm (56 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Osaka Low',
    description: <p></p>,
    images: [require('./images/osaka_low_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>44 cm x 44 cm x 36 cm (8,5 kg)</div>
            <div>53 cm x 53 cm x 40 cm (14,5 kg)</div>
            <div>70 cm x 70 cm x 45 cm (24 kg)</div>
            <div>90 cm x 90 cm x 50 cm (50 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Palma',
    description: <p></p>,
    images: [require('./images/palma_1.jpg'), require('./images/palma_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>46 cm x 78 cm (21 kg)</div>
            <div>47 cm x 106 cm (27 kg)</div>
            <div>88 cm x 76 cm (40 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Plantship',
    description: <p></p>,
    images: [
      require('./images/plantship_1.jpg'),
      require('./images/plantship_2.jpg'),
      require('./images/plantship_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>142 cm x 40 cm x 40/32 cm (27 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Quadra',
    description: <p></p>,
    images: [require('./images/quadra_1.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>34 cm x 34 cm x 55 cm (10 kg)</div>
            <div>36 cm x 36 cm x 65 cm (13,5 kg)</div>
            <div>38 cm x 38 cm x 75 cm (17 kg)</div>
          </>
        ),
      },
    ],
  },

  {
    name: 'Roberto',
    description: <p></p>,
    images: [
      require('./images/roberto_1.jpg'),
      require('./images/roberto_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>46 cm x 72 cm (9,8 kg)</div>
          </>
        ),
      },
    ],
  },

  {
    name: 'Samurai',
    description: <p></p>,
    images: [
      require('./images/samurai_1.jpg'),
      require('./images/samurai_2.jpg'),
      require('./images/samurai_3.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>44 cm x 44 cm x 65 cm (16 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Spindel',
    description: <p></p>,
    images: [
      require('./images/spindel_1.jpg'),
      require('./images/spindel_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>37 cm x 60 cm (6,5 kg)</div>
            <div>56 cm x 92 cm (14 kg)</div>
          </>
        ),
      },
    ],
  },

  {
    name: 'Sumo',
    description: <p></p>,
    images: [require('./images/sumo_1.jpg'), require('./images/sumo_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>35 cm x 40 cm (8 kg)</div>
            <div>45 cm x 50 cm (14 kg)</div>
          </>
        ),
      },
    ],
  },

  {
    name: 'Sushi',
    description: <p></p>,
    images: [require('./images/sushi_1.jpg'), require('./images/sushi_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>50 cm x 50 cm (17 kg)</div>
            <div>65 cm x 65 cm (30 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Tokyo',
    description: <p></p>,
    images: [require('./images/tokyo_1.jpg'), require('./images/tokyo_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>70 cm x 70 cm x 40 cm (16 kg)</div>
            <div>44 cm x 44 cm x 45 cm (10 kg)</div>
            <div>53 cm x 53 cm x 55 cm (19 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Twista',
    description: <p></p>,
    images: [
      require('./images/twista_1.jpg'),
      require('./images/twista_2.jpg'),
    ],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>60 cm x 30 cm x 60 cm (19 kg)</div>
            <div>60 cm x 60 cm x 60 cm (29,5 kg)</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Wave',
    description: <p></p>,
    images: [require('./images/wave_1.jpg'), require('./images/wave_2.jpg')],
    properties: [
      {
        name: 'Wymiary',
        value: (
          <>
            <div>57 cm x 34 cm x 57 cm (20 kg)</div>
          </>
        ),
      },
    ],
  },
];
