import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import image1 from './images/1.jpg';
import image2 from './images/2.jpg';
import image3 from './images/3.jpg';

function ProductMainPage() {
  return (
    <>
      <h2>Włókno cement</h2>
      <h6 className="mb-5 text-muted">Czym się charakteryzuje?</h6>

      <Row>
        <Col xs={12} md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>WYJĄTKOWE MOŻLIWOŚCI</Card.Title>
              <Card.Text>
                Dzięki specjalnemu wzmocnieniu z włókien, produkty są wyjątkowo
                lekkie w stosunku do ich wyjątkowej wytrzymałości oraz
                odporności na wszelkie zewnętrzne warunki i siły. Ich naturalne
                cechy idealnie nadają się do projektowania budownictwa
                wymagającego najlepszego połączenia atrakcyjnego wyglądu z
                lekkością i trwałością
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Img src={image3} />
          </Card>

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>NIE KORODUJĄ</Card.Title>
              <Card.Text>
                Włókno-cementowe produkty nie korodują i są odporne na działanie
                substancji chemicznych takich jak sole, potasy, amoniaki i
                chlory
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="mb-4">
            <Card.Img src={image2} />
          </Card>

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>ODPORNE NA UDERZENIA I ZABRUDZENIA</Card.Title>
              <Card.Text>
                Wyjątkowe połączenie włókien i cementu powoduje, że produkty są
                odporne na codzienne użytkowanie i zabrudzenia towarzyszące
                warunkom hodowlanym i magazynowym. Wszystkie stosowne produkty
                sprawdzone zostały za pomocą testu wytrzymałościowego
                Charpy’ego.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>NIEPALNE I OGNIOTRWAŁE</Card.Title>
              <Card.Text>
                Wszystkie produkty włókno-cementowe są niepalne oraz objęte
                klasyfikacją normy EN 13501-1. Niemożliwe jest, aby przyczyniły
                się do powstawania pożaru.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>ODPORNE NA WARUNKI ATMOSFERYCZNE</Card.Title>
              <Card.Text>
                Produkty włókno-cementowe są całkowicie odporne na temperatury
                poniżej 0o C, śnieg, zamarzanie, topnienie i deszcze – nawet te
                ulewne – jak również upały, wysoką wilgotność i bezpośrednie
                światło słoneczne.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Img src={image1} />
          </Card>

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>ODPORNOŚĆ BIOLOGICZNA</Card.Title>
              <Card.Text>
                Włókno-cement posiada wysoką wartość pH, która sprawia, że jest
                on wyjątkowo odporny na niszczenie przez glony i bakterie oraz
                porastanie mchem, grzybami itp. – nawet w warunkach i klimacie,
                gdzie często stanowi to problem.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ProductMainPage;
