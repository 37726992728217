import React from 'react';

function Error404() {
  return (
    <div className="mt-5 text-center">
      <h1>404</h1>
      <h4>Wybrana strona nie istnieje.</h4>
    </div>
  );
}

export default Error404;
