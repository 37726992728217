import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

import routes from "../routes";
import logo from "../logo.png";

import styles from "./MainLayout.module.scss";
import CookiesBanner from "./CookiesBanner/CookiesBanner";

function MainLayout({ children }) {
  const [showMenu, toggleMenu] = useState(false);
  const year = new Date().getFullYear();

  return (
    <>
      <div className={classNames(styles.menu)}>
        <Container className="align-items-center h-100">
          <Row className="align-items-center h-100">
            <Col
              xs={12}
              sm={4}
              className="align-items-center d-inline-flex justify-content-between"
            >
              <NavLink to="/">
                <img src={logo} alt="ADACHA Logo" className={styles.logo} />
              </NavLink>

              <svg
                className={classNames("d-sm-none", styles.hamburgerMenuButton)}
                onClick={() => {
                  toggleMenu(!showMenu);
                }}
                width="32px"
                height="32px"
                version="1.1"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
              </svg>
            </Col>
            <Col xs={12} sm={8} className="d-none d-sm-block">
              <Row className="justify-content-end">
                {routes.map((route, index) => {
                  return (
                    <Col xs={12} sm="auto" key={index}>
                      <NavLink
                        to={route.path}
                        exact={route.exact}
                        className={styles.menuLink}
                        activeClassName={styles.active}
                      >
                        {route.name}
                      </NavLink>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {showMenu && (
        <div className={classNames("d-sm-none", styles.responsiveMenu)}>
          <ListGroup variant="flush">
            {routes.map((route, index) => {
              return (
                <ListGroup.Item key={index} className="p-0">
                  <NavLink
                    to={route.path}
                    exact={route.exact}
                    className={classNames(
                      "d-block w-100 h-100 p-3",
                      styles.responsiveMenuLink
                    )}
                    onClick={() => toggleMenu(false)}
                  >
                    {route.name}
                  </NavLink>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      )}

      <div className={styles.layout}>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <Container>© {year} adacha.pl</Container>
        </div>
      </div>

      <CookiesBanner />
    </>
  );
}

export default MainLayout;
