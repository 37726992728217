import React from 'react';

import eternitLogo from './images/Eternit_logo.svg';
import ProductItem from '../../../../shared/ProductItem/ProductItem';

import list from './lista';

function Meble() {
  return (
    <>
      <h3 className="text-center mb-5">
        Naturalne materiały. Wysoka stabilność. Ręcznie wykonane.
      </h3>

      <div>
        <img
          className="float-left mr-3 mb-3"
          style={{ maxWidth: '20%' }}
          src={eternitLogo}
          alt="Eternit Österreich"
        />

        <p className="mb-5">
          Kolekcja produktów ogrodniczych i designerskich Eternit (Schweiz) AG
          rozpoczęła się w 1912 roku od legendarnej skrzynki na kwiaty Eternit
          (Schweiz) AG - pierwszej donicy wykonanej z włóknocementu . Odporność
          na warunki atmosferyczne, trwałość i wysoki poziom stabilności
          włóknocementu czynią go idealnym materiałem do użytku na zewnątrz. W
          latach pięćdziesiątych XX wieku szwajcarski architekt wnętrz i
          projektant mebli Willy Guhl stworzył Loop Chair, które jest obecnie
          uważane za jeden z największych klasyków designu. Od tego czasu inni
          projektanci również odkryli włókno-cement i sami badali jego
          plastyczność. Unikalne kolekcje ogrodów i projektów Eternit (Schweiz)
          AG są wynikiem pracy wszystkich tych projektantów.
        </p>
      </div>

      {list.map(product => (
        <ProductItem product={product} key={product.name} />
      ))}
    </>
  );
}

export default Meble;
