import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import Mailto from 'react-protected-mailto';

import styles from './Contact.module.scss';

import logo from '../../logo.png';
import SwisspearlLogo from './images/Swisspearl_logo.svg';

function Contact() {
  return (
    <Container className="mt-5 mb-5">
      <Row className="mb-2">
        <Col xs={12} sm={6}>
          <img
            src={logo}
            className={classNames('mb-5', styles.logo)}
            alt="ADACHA Logo"
          />
          <Row className="mb-2">
            <Col className={styles.contactRow} xs={12} sm="auto">
              Adres:
            </Col>
            <Col>
              ul. Samotna 4
              <br />
              61-441 Poznań
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className={styles.contactRow} xs={12} sm="auto">
              Telefon:
            </Col>
            <Col>
              <Mailto tel="+48 600 648 649" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className={styles.contactRow} xs={12} sm="auto">
              Email:
            </Col>
            <Col>
              <Mailto email="swisspearl@adacha.pl" target="_blank" />
              <br />
              <Mailto email="kontakt@adacha.pl" target="_blank" />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className={styles.contactRow} xs={12} sm="auto">
              NIP:
            </Col>
            <Col>622-178-78-00</Col>
          </Row>
        </Col>

        <Col xs={12} sm={6}>
          <h6 className="mb-3">Autoryzowany Dytrybutor Swisspearl w Polsce</h6>
          <img src={SwisspearlLogo} alt="Swisspearl Logo" />
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
