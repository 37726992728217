import React from 'react';

export default [
  {
    name: 'EuroFala W130-9 (B59)',
    description: (
      <>
        <p>
          Płyty faliste EuroFala znajdują zastosowanie nie tylko przy budowie
          nowych obiektów, ale również na budynkach modernizowanych i
          remontowanych. Wiele istniejących budynków wybudowanych w latach
          1960-90 posiada dachy, które wymagają wymiany pokrycia. System dachowy
          EuroFala został tak zaprojektowany, aby jego wykorzystanie w takich
          przypadkach było optymalne.
        </p>
      </>
    ),
    images: [require('./images/eurofala_w130.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 2,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A1 / A2 - s1, d0',
      },
      {
        name: 'Narożniki',
        value: 'Obcięte / Pełna płyta',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>6x1150x625 mm</div>
            <div>6x1150x1250 mm</div>
            <div>6x1150x1875 mm</div>
            <div>6x1150x2500 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'EuroFala W177-6,5 (B65)',
    description: (
      <>
        <p>
          Włókno-cementowe płyty faliste EuroFala W177-6,5 (B65) dostosowane są
          do architektury budynków mieszkalnych, budynków użyteczności
          publicznej oraz do reprezentacyjnych budynków użytkowych. Dostępność
          różnych wersji kolorystycznych oraz długości powoduje, iż można je
          dostosować nie tylko do wymagań estetycznych, ale również
          ekonomicznych i użytkowych obiektów.
        </p>
      </>
    ),
    images: [require('./images/eurofala_w177.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 8,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Narożniki',
        value: 'Obcięte / Pełna płyta',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>6x1093x1220 mm</div>
            <div>6x1093x1525 mm</div>
            <div>6x1093x2440 mm</div>
            <div>6x1095x625 mm</div>
            <div>6x1095x1250 mm</div>
            <div>6x1095x1600 mm</div>
          </>
        ),
      },
    ],
  },
];
