import React from 'react';

import Start from './pages/Start/Start';
import Produkty from './pages/Produkty/Produkty';
import Contact from './pages/Contact/Contact';

import Fasady from './pages/Produkty/Fasady/Fasady';

import PlytkiDachowe from './pages/Produkty/Dachy/PlytkiDachowe/PlytkiDachowe';
import PlytyFaliste from './pages/Produkty/Dachy/PlytyFaliste/PlytyFaliste';
import MembranyDachoweTyvek from './pages/Produkty/Dachy/MembranyDachoweTyvek/MembranyDachoweTyvek';

import Planters from './pages/Produkty/MebleWloknoCementowe/Planters/Planters';
import Meble from './pages/Produkty/MebleWloknoCementowe/Meble/Meble';

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Start',
    component: <Start />,
  },
  {
    path: '/produkty',
    name: 'Produkty',
    component: <Produkty />,
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: <Contact />,
  },
];

export const productRoutes = [
  {
    path: '/produkty/fasady',
    name: 'Fasady',
    component: <Fasady />,
  },
  {
    name: 'Dachy',
    children: [
      {
        path: '/produkty/dachy/plytki_dachowe',
        name: 'Płytki dachowe',
        component: <PlytkiDachowe />,
      },
      {
        path: '/produkty/dachy/płyty_faliste',
        name: 'Płyty faliste',
        component: <PlytyFaliste />,
      },
      {
        path: '/produkty/dachy/membrany_dachowe_tyvek',
        name: 'Membrany dachowe Tyvek',
        component: <MembranyDachoweTyvek />,
      },
    ],
  },
  {
    name: 'Garden & Design',
    children: [
      {
        path: '/produkty/meble-wlokno-cementowe/furniture_and_accessories',
        name: 'Meble i akcesoria',
        component: <Meble />,
      },
      {
        path: '/produkty/meble-wlokno-cementowe/planters',
        name: 'Planters',
        component: <Planters />,
      },
    ],
  },
];

export default routes;
