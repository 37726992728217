import React from 'react';

export default [
  {
    name: 'Dupont Tyvek® Soft',
    description: (
      <>
        <p>
          To podstawowa membrana do ochrony dachów i scian o wysokiej
          paroprzepuszczalności. Do stosowania bezpośrednio na wełnę mineralną
          (dach) lub termoizolację, albo poszycie budynku.
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_soft.jpg')],
    properties: [
      { name: 'Skład', value: 'polietylen' },
      { name: 'Materiał', value: 'HDPE' },
      { name: 'Ilość warstw', value: '1' },
      { name: 'Wymiar rolki', value: '50m x 1,5m' },
      { name: 'Siła zrywająca wzdłuż', value: '165 N/5cm' },
      { name: 'Siła zrywająca w poprzek', value: '140 N/5cm' },
      { name: 'Masa powierzchniowa', value: '80 g/m2' },
      { name: 'Paroprzepuszczalność', value: '3000 g/m2/24h' },
      { name: 'Grubość warstwy funkcjonalnej', value: '175 µm' },
      { name: 'Zakres temperatur użytkowania', value: '-40°C do +100°C' },
      { name: 'Ekspozycja na promieniowanie UV', value: '4 miesiące' },
      { name: 'Odporność na przesiąkanie wody', value: 'W1' },
      { name: 'Wartość współczynnika (opór dyfuzyjny) Sd', value: '0,025 m' },
    ],
  },
  {
    name: 'Dupont Tyvek® Pro/Pro z taśmą',
    description: (
      <>
        <p>
          Otwarta dyfuzyjnie membrana dachowa o wysokiej wytrzymałości
          mechanicznej. Produkt uniwersalny, stosowany do ochrony wszelkiego
          rodzaju budynków, stosowana zarówno na deskowanie jak i bezpośrednio
          na krokwie, również pod pokrycia metalowe.
        </p>
      </>
    ),
    images: [],
    properties: [
      { name: 'Gramatura', value: '125 g/m²' },
      { name: 'Wymiar rolki', value: '1,5 m x 50 mb = 75 m²' },
      { name: 'Skład', value: 'polietylen + polipropylen' },
      { name: 'Materiał', value: 'PP + HDPE' },
      { name: 'Ilość warstw', value: '2' },
      { name: 'Siła zrywająca wzdłuż', value: '265 N/5cm' },
      { name: 'Siła zrywająca w poprzek', value: '230 N/5cm' },
      { name: 'Masa powierzchniowa', value: '125 g/m²' },
      { name: 'Paroprzepuszczalność', value: '3000 g/m²/24h' },
      { name: 'Grubość warstwy funkcjonalnej', value: '175 µm' },
      { name: 'Zakres temperatur użytkowania', value: 'od -40°C do +100°C' },
      { name: 'Ekspozycja na promieniowanie UV', value: '4 miesiące' },
      { name: 'Odporność na przesiąkanie wody', value: 'klasa W1' },
      { name: 'Wartość współczynnika (opór dyfuzyjny) Sd', value: '0,015 m' },
    ],
  },
  {
    name: 'Dupont Tyvek® Solid',
    description: (
      <>
        <p>
          Otwarta dyfuzyjnie membrana do ochrony wszelkiego rodzaju budynków o
          wysokiej wytrzymałości warstwy funkcjonalnej. Wysoka odporność na
          warunki atmosferyczne. Produkt do stosowania bezpośrednio na krokwie
          lub na deskowanie, bardzo popularny ze względu na relatywnie niską
          cenę i dużą uniwersalność, gwarantujący najwyższą ochronę. Biały kolor
          z szarym z nadrukiem antyrefleksyjnym po zewnętrznej stronie.
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_solid.jpg')],
    properties: [
      { name: 'Skład', value: 'polietylen' },
      { name: 'Materiał', value: 'HDPE' },
      { name: 'Ilość warstw', value: '1' },
      { name: 'Wymiar rolki', value: '1,5 m x 50 mb' },
      { name: 'Siła zrywająca wzdłuż', value: '245 N/5cm' },
      { name: 'Siła zrywająca w poprzek', value: '215 N/5cm' },
      { name: 'Masa powierzchniowa', value: '80 g/m²' },
      { name: 'Paroprzepuszczalność', value: '2000 g/m²/24h' },
      { name: 'Grubość warstwy funkcjonalnej', value: '220 µm' },
      { name: 'Zakres temperatur użytkowania', value: 'od -40°C do +100°C' },
      { name: 'Ekspozycja na promieniowanie UV', value: '4 miesiące' },
      { name: 'Odporność na przesiąkanie wody', value: 'klasa W1' },
      { name: 'Wartość współczynnika (opór dyfuzyjny) Sd', value: '0,03 m' },
    ],
  },
  {
    name: 'Dupont Tyvek® Supro/Supro z taśmą ',
    description: (
      <>
        <p>
          Produkt o najwyższej odporności na warunki atmosferyczne i uszkodzenia
          przy montażu, w pełni uniwersalny. Do stosowania we wszelkiego rodzaju
          budynkach. Może być układany na deskowaniu jak i bez deskowania.
          Wysoka odporność mechaniczna w połączeniu z niezwykle wytrzymałą,
          wzmocnioną warstwą funkcjonalną o grubości 220 µm - to cechy
          niespotykane wśród produktów o tak niewielkim oporze dyfuzyjnym (wsp.
          Sd = 0,02 m). Dzięki temu zapewnia wyjątkową kombinację szczelności i
          kontroli przenikania pary wodnej. Dostępny także ze zintegrowaną taśmą
          klejącą. Kolor biały od spodu, szary z zewnątrz.
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_supro.jpg')],
    properties: [
      { name: 'Skład', value: 'polietylen + polipropylen' },
      { name: 'Materiał', value: 'PP + HDPE' },
      { name: 'Ilość warstw', value: '2' },
      { name: 'Wymiar rolki', value: '1,5 m x 50 mb' },
      { name: 'Siła zrywająca wzdłuż', value: '340 N/5cm' },
      { name: 'Siła zrywająca w poprzek', value: '295 N/5cm' },
      { name: 'Masa powierzchniowa', value: '148 g/m²' },
      { name: 'Paroprzepuszczalność', value: '2000 g/m²/24h' },
      { name: 'Grubość warstwy funkcjonalnej', value: '220 µm' },
      { name: 'Zakres temperatur użytkowania', value: 'od -40°C do +100°C' },
      { name: 'Ekspozycja na promieniowanie UV', value: '4 miesiące' },
      { name: 'Odporność na przesiąkanie wody', value: 'klasa W1' },
      { name: 'Wartość współczynnika (opór dyfuzyjny) Sd', value: '0,02 m' },
    ],
  },
  {
    name: 'Dupont Tyvek® Housewrap',
    description: (
      <>
        <p>
          Tyvek® Housewrap to paroprzepuszczalna membrana o wysokich parametrach
          użytkowych, przeznaczona głównie do ścian wykonanych w konstrukcji
          szkieletu drewnianego, ale także do szkieletu stalowego i konstrukcji
          betonowych. Membranę montuje się na powierzchni ściany, bezpośrednio
          do płyt wierzchnich lub bloczków. Membrana DuPont™ Tyvek® Housewrap
          zapewnia trwałą ochronę ścian przed wilgocią, a dzięki oddychalności
          ogranicza kondensację.
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_housewrap.jpg')],
    properties: [
      { name: 'Gramatura', value: '63 g/m²' },
      { name: 'Wymiar', value: '1,5 m x 50 m' },
      { name: 'Zakres gramatur', value: 'do 110 g/m²' },
      { name: 'Reakcja na ogień', value: 'Klasa E' },
      { name: 'Odporność na przesiąkanie wody', value: 'Klasa W1' },
      { name: 'Przenikanie pary wodnej Sd', value: '0,01 m' },
      {
        name: 'Wytrzymałość mechaniczna przy rozciąganiu w kierunku podłużnym',
        value: '310 N/50 mm',
      },
      {
        name:
          'Wytrzymałość mechaniczna przy rozciąganiu w kierunku poprzecznym',
        value: '310 N/50 mm',
      },
      {
        name: 'Odporność na przesiąkanie wody po sztucznym starzeniu',
        value: 'Klasa W1',
      },
      { name: 'Wydłużenie w kierunku podłużnym', value: '17 %' },
      { name: 'Wydłużenie w kierunku poprzecznym', value: '20 %' },
      {
        name: 'Wytrzymałość na rozdzieranie w kierunku poprzecznym',
        value: '50 N',
      },
      {
        name: 'Wytrzymałość na rozdzieranie w kierunku podłużnym',
        value: '55 N',
      },
      {
        name: 'Zawartość substancji niebezpiecznych',
        value: 'Nie zadeklarowano',
      },
      { name: 'Pasek klejący', value: 'Nie' },
    ],
  },
  {
    name: 'DuPont Tyvek® FireCurb Housewrap',
    description: (
      <>
        <p>
          DuPont™ Tyvek® FireCurb™ Housewrap to lekka, zaawansowana membrana
          trudnopalna, która samorzutnie gasi płomień po za paleniu.
          Bezhalogenowa powłoka trudnopalna membrany Tyvek® Firecurb™ ogranicza
          powstawanie płonących kropli i dymu przy minimalnym wpływie na
          środowisko. Klasyfikacja ogniowa do Euroclass B (bez mocowania), która
          jest nieosiągalna dla innych membran.
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_firecurb_housewrap.jpg')],
    properties: [
      { name: 'Wymiary rolki', value: '1,5 m x 50 m' },
      { name: 'Masa rolki', value: '5,5kg' },
      { name: 'Skład', value: 'Włóknina polietylenowa z powłoką trudnopalną' },
      {
        name: 'Klasyfikacja ogniowa',
        value:
          'zgodnie z normą EN 13501-1 (na wełnie mineralnej i bez mocowania) B-s1,d0',
      },
      { name: 'Odporność na termperaturę', value: '–40°C to +100°C' },
      { name: 'Współczynnik oporu dyfuzyjnego (Sd)', value: '0,014m' },
      { name: 'Gramatura', value: '68g/m2' },
      { name: 'Grubość warstwy funkcjonalnej', value: '175μm' },
    ],
  },
  {
    name: 'Dupont Tyvek® UV Facade',
    description: (
      <>
        <p>
          Membrana DuPont™ Tyvek® UV Facade została specjalnie zaprojektowana,
          aby zapewniać trwałą ochronę przed promieniowaniem UV i wilgocią w
          otwartych i wentylowanych systemach okładzin przeciwdeszczowych
        </p>
      </>
    ),
    images: [require('./images/dupont_tyvek_uv_facade.jpg')],
    properties: [
      {
        name: 'Skład',
        value: 'Kompozyt polietylenu o wysokiej gęstości oraz polipropylenu',
      },
      { name: 'Wymiary rolki', value: '1,5m x 50m, value: 3,0m x 50m' },
      { name: 'Masa rolki', value: '15kg, value: 30kg' },
      { name: 'Gramatura', value: '195g/m2' },
      { name: 'Grubość produktu/warstwy funkcjonalnej', value: '600/220µm' },
      { name: 'Współczynnik oporu dyfuzyjnego (Sd)', value: '0,035mm' },
    ],
  },
];
