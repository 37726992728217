import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './ProductItem.module.scss';

function ProductItem(props) {
  const {
    product: { name, description, images = [], properties },
  } = props;

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col xs={12} md={4}>
          {images.length === 1 && (
            <img className="d-block w-100" src={images[0]} alt={name} />
          )}

          {images.length > 1 && (
            <Carousel interval={null} className={styles.carousel}>
              {images.map((image, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image} alt={name} />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          )}
        </Col>
        <Col xs={12} md={8}>
          <h3 className="mb-3">{name}</h3>
          <div className="text-justify mb-4">{description}</div>

          <Row>
            {properties.map(prop => {
              return (
                <Col key={prop.name} xs={12} sm={4} className="mb-4">
                  <div className={classNames('mb-2 pb-2', styles.label)}>
                    {prop.name}
                  </div>
                  <div className={classNames('', styles.property)}>
                    {prop.value}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductItem;
