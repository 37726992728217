import React from 'react';

export default [
  {
    name: 'Patina Original',
    description: (
      <>
        <p>Specjalne cechy produktu:</p>
        <ul>
          <li>Naturalny i autentyczny wygląd</li>
          <li>Drobne linie szlifowane na powierzchni</li>
          <li>Patynowanie wzmacnia indywidualny wyraz Twojego budynku</li>
          <li>Praktycznie bezobsługowe</li>
        </ul>
      </>
    ),
    images: [require('./images/patina_original.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 11,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x592x2992 mm</div>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
            <div>8x1250x2500 mm</div>
            <div>8x1250x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Patina Rough',
    description: (
      <>
        <p>Specjalne cechy produktu:</p>
        <ul>
          <li>Gładkie wykończenie z efektem kamienia</li>
          <li>Zerodowana powierzchnia, jakby uformowana przez naturę</li>
          <li>
            Wiele możliwości projektowych w połączeniu z innymi produktami
            Patina
          </li>
        </ul>
      </>
    ),
    images: [require('./images/patina_rough.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 2,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
            <div>8x1250x2500 mm</div>
            <div>8x1250x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Patina Inline',
    description: (
      <>
        <p>Specjalne cechy produktu:</p>
        <ul>
          <li>
            Liniowa faktura stworzona przez starannie zaprojektowane frezowane
            rowki, które nadają budynkowi niepowtarzalny efekt 3D
          </li>
          <li>Możliwość dopasowania do wzoru kwadratu lub trójkąta</li>
          <li>Możliwość uwidocznienia linii w projekcie budynku</li>
        </ul>
      </>
    ),
    images: [require('./images/patina_inline.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 2,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
            <div>8x1250x2500 mm</div>
            <div>8x1250x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Cover',
    description: (
      <>
        <p>
          Cover to idealne rozwiązanie dla osób, które preferują mocne kolory i
          śmiałe projekty. Naturalna szara masa włókno-cementowa jest w pełni
          pokryta warstwą farby akrylowej na bazie wody, dostępnej w 26
          standardowych kolorach i ponad 2000 kolorów NCS®©.
        </p>
      </>
    ),
    images: [require('./images/cover.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 26,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
            <div>8x1250x2500 mm</div>
            <div>8x1250x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Solid',
    description: (
      <>
        <p>
          Wyjątkową zaletą płyt Solid jest to, że ich kolor pozostaje niezmienny
          przez cały okres użytkowania. Barwa masy jest dopasowana do żywych i
          trwałych powierzchni w pełni pokrytych farbą o takim samym kolorze.
        </p>
      </>
    ),
    images: [require('./images/solid.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 18,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Transparent',
    description: (
      <>
        <p>
          Płyty elewacyjne Transparent łączą teksturowane niuanse i surowość
          naturalnego materiału z trwałością przezroczystej powłoki. Kolor
          dodany do włókno-cementu odsłania i podkreśla włókna oraz inne
          surowce, które gwarantują wytrzymałość i nadają charakter płycie.
        </p>
      </>
    ),
    images: [require('./images/transparent.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 15,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x1192x2500 mm</div>
            <div>8x1192x3050 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Deska włóknocementowa Plank',
    description: (
      <>
        <p>
          <li>naturalny wygląd drewna bez potrzeby konserwacji</li>
          <li>prostota montażu</li>
          <li>odporność na promienie UV</li>
          <li>odporność na wilgoć</li>
          <li>szeroka paleta kolorów</li>
          <li>najdłuższa na rynku, 15 letnia gwarancja</li>
        </p>
      </>
    ),
    images: [require('./images/plank.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 20,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>8x180x3600 mm</div>
          </>
        ),
      },
    ],
  },
  {
    name: 'Construction',
    description: (
      <>
        <p>
          Mocne, wytrzymałe i naturalne płyty Construction (Raw) to istota
          włókno-cementu. Surowe, bez dodatkowej obróbki, z ich autentycznym,
          naturalnym cementowym, szarym odcieniem. Za tym prostym, choć
          subtelnym rozwiązaniem stoją jednak bardzo istotne zalety. Płyty w
          trakcie długotrwałego użytkowania zachowują wszystkie parametry
          techniczne i wytrzymałościowe. Pozostają odporne na trudne warunki
          atmosferyczne, działanie wody, grzybów, pleśni itp. Jednocześnie nie
          tracą nic ze swojej naturalności, co czyni je idealnym materiałem we
          współczesnym budownictwie.
        </p>
      </>
    ),
    images: [require('./images/construction.jpg')],
    properties: [
      {
        name: 'Kolory',
        value: 1,
      },
      {
        name: 'Klasa ogniowa',
        value: 'A2 - s1, d0',
      },
      {
        name: 'Wymiary',
        value: (
          <>
            <div>6 x 1200 x 2500</div>
            <div>6 x 1250 x 2500</div>
            <div>8 x 1192 x 2500 / 3050</div>
            <div>8 x 1200 x 2500 / 3050</div>
            <div>8 x 1250 x 2500</div>
            <div>10 x 1192 x 3050</div>
            <div>10 x 1200 x 3050</div>
          </>
        ),
      },
    ],
  },
];
