import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
} from 'react-router-dom';
import { Container, Row, Nav, NavDropdown } from 'react-bootstrap';

import { productRoutes } from '../../routes';
import ProductMainPage from '../ProductMainPage/ProductMainPage';
import Error404 from '../Errors/Error404';

import styles from './Produkty.module.scss';

function Produkty() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const isActive = path => {
    return path === pathname || pathname.startsWith(path);
  };

  return (
    <>
      <div className={styles.productsMenu}>
        <Container>
          <Row>
            <Nav variant="pills">
              {productRoutes.map(route => {
                if (!route.children) {
                  return (
                    <Nav.Item key={route.name}>
                      <Nav.Link
                        as={Link}
                        to={route.path}
                        className={isActive(route.path) && styles.active}
                      >
                        {route.name}
                      </Nav.Link>
                    </Nav.Item>
                  );
                }

                return (
                  <NavDropdown
                    key={route.name}
                    title={route.name}
                    className={
                      route.children.some(r => isActive(r.path)) &&
                      styles.active
                    }
                  >
                    {route.children.map(childRoute => {
                      return (
                        <NavDropdown.Item
                          key={childRoute.name}
                          as={Link}
                          to={childRoute.path}
                        >
                          {childRoute.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              })}
            </Nav>
          </Row>
        </Container>
      </div>

      <Container className="mt-5 mb-5">
        <Switch>
          <Route exact path={path}>
            <ProductMainPage />
          </Route>

          {productRoutes.map(route => {
            if (!route.children) {
              return (
                <Route key={route.name} path={route.path}>
                  {route.component}
                </Route>
              );
            }

            return route.children.map(childRoute => {
              return (
                <Route key={childRoute.name} path={childRoute.path}>
                  {childRoute.component}
                </Route>
              );
            });
          })}

          <Route>
            <Error404 />
          </Route>
        </Switch>
      </Container>
    </>
  );
}

export default Produkty;
