import React from 'react';

import ProductItem from '../../../../shared/ProductItem/ProductItem';

import list from './lista';

function Planters() {
  return (
    <>
      <h1>Planters</h1>

      {list.map(product => (
        <ProductItem product={product} key={product.name} />
      ))}
    </>
  );
}

export default Planters;
