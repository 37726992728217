import React from 'react';

import dupontWorkersImage from './images/dupont_workers.jpg';

import ProductItem from '../../../../shared/ProductItem/ProductItem';

import list from './lista';

function MembranyDachoweTyvek() {
  return (
    <>
      <div>
        <img
          className="float-left mr-3 mb-3"
          style={{ maxWidth: '40%' }}
          src={dupontWorkersImage}
          alt="Dupont Tyvek"
        />

        <p>
          <span className="font-weight-bold">Tyvek®</span> jest częścia grupy
          DuPont – jeden z największych koncernów chemicznych na świecie. Firma
          została założona w czerwcu 1802 roku i zajmowała się produkcją
          czarnego prochu. Jego naukowcy jako pierwsi wytworzyli takie materiały
          jak nylon, teflon oraz kevlar.
        </p>

        <p>
          <span className="font-weight-bold">DuPont Tyvek®</span> jest znanym na
          całym świecie jako wodoszczelny i paropszepuszczalny materiał
          stosowany do ochrony przed wilgocią oraz stratami ciepła. Proces
          produkcji polegający na wytworzeniu mocnego i szczelnego pasma włókien
          polietylenowych (HDPE), powstałych na skutek gwałtownego rozprężenia
          roztworu polietylowego, przechodzace przez specjalne dysze
          przędzalnicze. Cały proces ze względu na konieczność występowania
          wysokich ciśnień i temperatur oraz wybuchowy charakter wytwarzania
          włókien, kontrolowany jest automatycznie przez dziesiątki czujników
          przesyłajacych do dane do komputera sterującego.
        </p>

        <p className="mb-5">
          Warto podkreślić, iż produkowane w ten sposób warstwy funkcjonalne
          cechuje większa niż w innych technologiach grubość (dostępne obecnie
          membrany Tyvek® oparte sa na warstwach o grubości 175µm i 220 µm) co
          ma bezpośredni wpływ na żywotność i wieloletnią funkcjonalnosc
          mambrany Tyvek®.
        </p>
      </div>

      {list.map(product => (
        <ProductItem product={product} key={product.name} />
      ))}
    </>
  );
}

export default MembranyDachoweTyvek;
